const FullScreenCardRight = () => import('../../../views/FullScreenCardRight.vue');
const LinkHandlerModule = () => import('../components/LinkHandlerModule.vue');

export default [
  {
    path: '/verification',
    meta: { requiresAuth: false, guestOnly: false },
    component: FullScreenCardRight,
    children: [
      {
        path: ':email/:token/:accept(0|1)',
        name: 'linkVerification',
        component: LinkHandlerModule,
        props(route) {
          const { email, token, accept } = route.params;
          return {
            email,
            token,
            accept: accept ? Number(accept) : 0,
          };
        },
      },
    ],
  },
];
