import Vue from 'vue';
import { Client } from '@/services';
import { jsonToFormData } from '../../../utils/http-common';
import { Services } from '../../../store/actions/api';
import { captureException } from '@sentry/vue';

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
    };
  },
  getters: {},
  mutations: {
    loading(state, payload) {
      this.state.loading = payload;
    },
  },
  actions: {
    initialize({ dispatch }) {
      console.info('System initializing...');
      console.info('System initialized.');
    },

    async verify({ state, dispatch, commit }, { email, token, accept }) {
      if (state.loading) {
        return null;
      }

      let params = {
        method: Services.FlightScopeUser.methods.UserVerification.key,
        service: Services.FlightScopeUser.key,
      };

      let payload = {
        Email: email,
        Key: token,
        IsAccepted: accept,
        ReturnObject: 1,
      };

      let data = jsonToFormData(payload);

      try {
        commit('loading', true);
        const response = await Client({
          data, params, method: 'post', localError: true
        });

        return { type: response.statusText, data: response.data };
      } catch (error) {
        Vue.$log.debug(error);
        captureException(error);

        const { response } = error;
        const { data } = response;

        return data;
      } finally {
        commit('loading', false);
      }
    },
  },
};
